@import "styles/utils/mixins";

.wrapper {
  padding: 35px 0;
}

.content {
  position: relative;
}

.title {
  width: 80%;
  font-family: "TT Interfaces", sans-serif;
  margin-bottom: 90px;
}

.description {
  width: 29%;
}

.top-circle {
  position: absolute;
  right: 0;
  bottom: 45px;
  left: 0;
  transform: translate(0, 50%);
  display: flex;
  justify-content: center;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    transform: translate(0, -30%);
    width: 1290px;
    height: 1290px;
    border-radius: 50%;
    border: 1px solid rgb(206 16 80 / 30%);
    pointer-events: none;
  }
}

.inside-circle {
  position: relative;
  width: 540px;
  height: 540px;
  border: 1px solid rgb(206 16 80 / 30%);
  border-radius: 50%;
}

.link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translate(-45px, -50%);
  left: 50%;
  white-space: nowrap;
}

.arrow {
  position: relative;
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #ce1050;
  margin: 0 38px 0 0;

  path:first-of-type {
    stroke: #fff;
  }

  path:last-of-type {
    fill: #fff;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin: auto;
}

@media (max-width: 1280px) {
  .top-circle::after {
    width: 1025px;
    height: 1025px;
    transform: translate(0, -25%);
  }
}

@include small-screen {
  .wrapper {
    padding: 35px 0 150px;
  }

  .title {
    width: 100%;
    margin-bottom: 40px;
  }

  .description {
    width: 80%;
  }

  .top-circle {
    position: absolute;
    right: initial;
    bottom: -70px;
    left: -115px;

    &::after {
      width: 640px;
      height: 640px;
      border: 1px solid rgb(206 16 80 / 20%);
      transform: translate(-5%, -25%);
    }
  }

  .inside-circle {
    width: 320px;
    height: 320px;
    border: 1px solid rgb(206 16 80 / 20%);
  }

  .arrow {
    width: 64px;
    height: 64px;
    margin: 0 10px 0 0;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}
